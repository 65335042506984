export const companyListToDropdownFormat = (
    list: any[],
    labelParam: string,
    valueParam: string,
) => {
    const options: any[] = [];
    if (list && list.length > 0) {
        list.map((item) => {
            const optionObj = { id: "", label: "", value: "" };
            optionObj.id = item.companyId;
            optionObj.label = labelParam ? item[labelParam] : item;
            optionObj.value = valueParam ? item[valueParam] : item;
            options.push(optionObj);
        });
    }
    return options;
};