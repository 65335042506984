export const POSTAL_CODE_REGEX = /^[a-z\d\-\s]+$/i;
export const ALPHABET_REGEX = /^[aA-zZ\s]+$/;
export const ALPHA_NUMERIC = /^[aA-zZ0-9.,-\s]+$/;
export const PHONE_NUM_REGEX = /^[0-9-+().\s]*$/;
export const ERROR_MESSAGE_NO_FIELD = `Please enter atleast one of the fields value to Proceed. 
Please refer above mentioned search criteria`;
export const ERROR_MSG_MIN_CHAR = `Please enter atleast 3 characters to fetch results.`;
export const DESC = "DESC";
export const ASC = "ASC";
export const SORT_COL = "regDate";
export const PAGE_INFO_OBJ = {
    activeDelta: 10,
    activePage: 1,
    ellipsisBuffer: 2,
    totalItems: 0,
};
export const SEARCH_PANEL_INITIAL_VAL = {
    emailOrName: "",
    company: "",
    isActive: false,
};
export const INITIAL_FORM_OBJ = {
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    middleName: "",
    companyName: "",
    jobTitle: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    province: "",
    phone: "",
    country: "",
    postalCode: "",
    mobile: "",
    broadcomEmail: "",
    fax: "",
    defaultCurrency: "",
    stateName: "",
    isEnterprise: false,
    companyId: "",
    otherCompanyName: "",
    supportId: "",
    sourceId: "",
    registrationId: "",
    uuid: null,
    siteId: null,
    isActive: false,
};
export const deltas = [
    {
        label: 10,
    },
    {
        label: 20,
    },
    {
        label: 30,
    },
    {
        label: 40,
    },
    {
        label: 50,
    },
];

export const currencyList = [
    {
        label: "Select Default Currency",
        value: "",
    },
    {
        label: "Euro (EUR)",
        value: "Euro (EUR)",
    },
    {
        label: "Japanese Yen (JPY)",
        value: "Japanese Yen (JPY)",
    },
    {
        label: "U.S. Dollar (USD)",
        value: "U.S. Dollar (USD)",
    },
];

export const CANCEL_EDIT_POPUP_HEADING = "Cancel Profile Edit";
export const CANCEL_EDIT_CONFIRM_MESSAGE =
    "Changes are not saved, are you sure you want to proceed?";
export const USER_NOT_FOUND_MESSAGE = "User not found in Okta";
export const SELECT_STATE = "Select State";
export const SELECT_JTITLE = "Select Job Title";
export const SELECT_COUNTRY = "Select Country";
export const SPECIAL_CHAR = "Special characters are not allowed";
export const MAX_40 = "Maximum allowed characters is 40";
export const USER_NOT_GTM_SCREENED_MESSAGE = "User is not gtm screened";
