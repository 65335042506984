import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../common-ui-modules/service/redux-helper/api-action-creator";
import { showAlert } from "../../common-ui-modules/service/redux-helper/store/slice/globalAlertHandler.slice";
import { api } from "../../common-ui-modules";
import { API_URL } from "./constants";
import formatUserInfoVO from "./service/formatUserInfoVO";
import { UpdateUserInfo } from "./service/UpdateUserInfoService";
import {
    DESC,
    INITIAL_FORM_OBJ,
    PAGE_INFO_OBJ,
    SEARCH_PANEL_INITIAL_VAL,
    SELECT_COUNTRY,
    SELECT_JTITLE,
    SELECT_STATE,
    SORT_COL,
} from "../../modules/UserManagement/ui/constants/userMgmtConstants";
import { downloadFile, getEncryptedCookie } from "../../utils/utils";
import { DEFAULT_STATE } from "../../modules/Registration/ui/common/constants";
import { axiosApi } from "../../common-ui-modules/service/redux-helper/middleware/axios-api";

const slice = createSlice({
    name: "userManagement",
    initialState: {
        companyNameList: [],
        userDetails: [],
        userInfoVO: {},
        countriesList: [{ label: SELECT_COUNTRY, value: "" }],
        countriesValueList: [],
        jobTitleArr: [{ label: SELECT_JTITLE, value: "" }],
        stateVO: [{ label: SELECT_STATE, value: "" }],
        searchInitialValues: SEARCH_PANEL_INITIAL_VAL,
        initialValues: INITIAL_FORM_OBJ,
        pageInfo: PAGE_INFO_OBJ,
        sortInfo: {
            column: SORT_COL,
            order: DESC,
        },
        saveStatus: "",
        isEdit: false,
        emailIdToEdit: "",
        userInfo: {},
        userInfoReceived: false,
        searchDetailsPayload: null,
    },
    reducers: {
        companyNamesReceived: (userManagement, action) => {
            if (action.payload) {
                userManagement.companyNameList = action.payload;
            }
        },
        userDetailsReceived: (userManagement, action) => {
            if (action.payload) {
                userManagement.userDetails = action.payload.result;
                userManagement.pageInfo.totalItems = action.payload.result[0].totalCount;
                userManagement.pageInfo.activeDelta = action.payload.result[0].rowCount;
                userManagement.pageInfo.activePage = action.payload.result[0].pageNo;
            }
        },
        resetGrid: (userManagement) => {
            userManagement.userDetails = [];
            userManagement.pageInfo = PAGE_INFO_OBJ;
        },
        setSearchInitialValues: (userManagement, action) => {
            if (action.payload) {
                userManagement.searchInitialValues = action.payload;
            }
        },
        setPageInfo: (state, action) => {
            if (action.payload) {
                state.pageInfo = action.payload;
            }
        },
        resetInitialValues: (userManagement) => {
            (userManagement.countriesList = [{ label: SELECT_COUNTRY, value: "" }]),
                (userManagement.jobTitleArr = [{ label: SELECT_JTITLE, value: "" }]),
                (userManagement.stateVO = [{ label: SELECT_STATE, value: "" }]),
                (userManagement.initialValues = INITIAL_FORM_OBJ);
        },
        resetStateVO: (userManagement) => {
            userManagement.stateVO = [{ label: SELECT_STATE, value: "" }];
        },
        setSortInfo: (state, action) => {
            state.sortInfo = action.payload;
        },
        userInfoVOReceived: (userManagement, action) => {
            userManagement.userInfo = action.payload.data ? action.payload.data : {};
            const userInfoList: any = formatUserInfoVO(
                action.payload.data ? action.payload.data : {},
            );
            const initialValuesState = userManagement.initialValues;
            if (userInfoList && userInfoList.company) {
                initialValuesState.firstName = userInfoList.firstName;
                initialValuesState.company = userInfoList.company;
                initialValuesState.lastName = userInfoList.lastName;
                initialValuesState.email = userInfoList.email;
                initialValuesState.country = userInfoList.country;
                initialValuesState.middleName = userInfoList.middleName;
                initialValuesState.companyName = userInfoList.companyName;
                initialValuesState.companyId = userInfoList.companyId;
                initialValuesState.jobTitle = userInfoList.jobTitle;
                initialValuesState.addressLine1 = userInfoList.addressLine1;
                initialValuesState.addressLine2 = userInfoList.addressLine2;
                initialValuesState.city = userInfoList.city;
                initialValuesState.stateName = userInfoList.state;
                initialValuesState.province = userInfoList.province;
                initialValuesState.phone = userInfoList.phone;
                initialValuesState.postalCode = userInfoList.postalCode;
                initialValuesState.mobile = userInfoList.mobile;
                initialValuesState.broadcomEmail = userInfoList.broadcomEmail;
                initialValuesState.fax = userInfoList.fax;
                initialValuesState.defaultCurrency = userInfoList.defaultCurrency;
                initialValuesState.isEnterprise = userInfoList.isEnterprise;
                initialValuesState.isActive = userInfoList.isActive;
                (initialValuesState.otherCompanyName = userInfoList.otherCompanyName),
                    (initialValuesState.supportId = userInfoList.supportId),
                    (initialValuesState.sourceId = userInfoList.sourceId),
                    (initialValuesState.registrationId = userInfoList.registrationId),
                    (initialValuesState.uuid = null),
                    (initialValuesState.siteId = null);
            }
            userManagement.userInfoVO = userInfoList;
            userManagement.userInfoReceived = true;
        },
        allMasterVOReceived: (userManagement, action) => {
            if (action.payload) {
                const masterVO = action.payload.data;
                let countries = [];
                countries = masterVO.result.map((item: any) => {
                    return { label: item.countryName, value: item.countryId };
                });
                countries.unshift({ label: SELECT_COUNTRY, value: null });
                userManagement.countriesList = countries;
                //country values
                const countryList: any = [];
                countries.map((item: any) => {
                    !!item.value && countryList.push(item.value.toString());
                });
                userManagement.countriesValueList = countryList;
            }
        },
        stateVOReceived: (userManagement, action) => {
            userManagement.stateVO = action.payload;
        },
        jobTitleArrReceived: (userManagement, action) => {
            userManagement.jobTitleArr = action.payload;
        },
        updateSaveReceive: (userManagement, action) => {
            userManagement.saveStatus = action.payload;
        },
        setIsEdit: (userManagement, action) => {
            if (action.payload[0]) {
                userManagement.isEdit = action.payload[0];
                userManagement.emailIdToEdit = action.payload[1];
            } else {
                userManagement.isEdit = false;
            }
        },
        resetUserInfo: (userManagement) => {
            userManagement.userInfo = {};
        },
        resetUserInfoReceivedStatus: (userManagement) => {
            userManagement.userInfoReceived = false;
        },
        setSearchDetailsPayload: (userManagement, action) => {
            userManagement.searchDetailsPayload = action.payload;
        },
        resetSearchDetailsPayload: (userManagement) => {
            userManagement.searchDetailsPayload = null;
        },
    },
});

export const {
    companyNamesReceived,
    userDetailsReceived,
    userInfoVOReceived,
    allMasterVOReceived,
    stateVOReceived,
    setPageInfo,
    setSortInfo,
    jobTitleArrReceived,
    setSearchInitialValues,
    resetInitialValues,
    updateSaveReceive,
    resetGrid,
    setIsEdit,
    resetUserInfo,
    resetUserInfoReceivedStatus,
    resetStateVO,
    setSearchDetailsPayload,
    resetSearchDetailsPayload,
} = slice.actions;

export const fetchCompanyNames = (searchText: any) => (dispatch: any) => {
    api({
        url: API_URL.FETCH_COMPANY,
        method: "POST",
        data: {
            searchString: searchText,
        },
    }).then((res) => {
        if (res && res.data && res.data.data && res.data.data.result) {
            const result = res.data.data.result;
            let formattedCompanyList = [];
            if (result.length > 0) {
                formattedCompanyList = result.map((item: any) => ({
                    label: item.companyName,
                    value: item.companyId,
                }));
            }
            dispatch(companyNamesReceived(formattedCompanyList));
        }
    });
};
export const fetchUserDetails = (data: any) => (dispatch: any) => {
    const headers = {
        "Content-Type": "application/json",
    };
    dispatch(setSearchDetailsPayload(data));
    api({
        url: API_URL.USER_SEARCH,
        method: "POST",
        data,
        headers,
        skipErrorHandling: true,
    })
        .then((res) => {
            if (res && res.data && res.data.success && res.data.data && res.data.data.result) {
                dispatch(userDetailsReceived(res.data.data));
            }
        })
        .catch((error) => {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors[0] &&
                error.response.data.errors[0].errorMessage
            )
                dispatch(
                    showAlert({
                        type: "danger",
                        message: error.response.data.errors[0].errorMessage
                            ? error.response.data.errors[0].errorMessage
                            : "Something went wrong",
                    }),
                );
        });
};

export const exportUserDetails = (data: any) => (dispatch: any) => {
    const headers = {
        RESPONSETYPE: "stream",
    };
    const postBody = { ...data };
    delete postBody["pageNo"];
    delete postBody["rowCount"];
    axiosApi({
        url: API_URL.USER_SEARCH + "?responseFormat=file",
        method: "POST",
        data: postBody,
        headers,
        skipErrorHandling: true,
        responseType: "blob",
    })
        .then((res) => {
            downloadFile(res, `UserSearch_Report.xlsx`);
        })
        .catch((error) => {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors[0] &&
                error.response.data.errors[0].errorMessage
            )
                dispatch(
                    showAlert({
                        type: "danger",
                        message: error.response.data.errors[0].errorMessage
                            ? error.response.data.errors[0].errorMessage
                            : "Something went wrong",
                    }),
                );
        });
};

export const fetchUserInfo = (email: any) => (dispatch: any, getState: any) => {
    const encryptedEmail = getEncryptedCookie(email);
    return dispatch(
        apiCallBegan({
            url: API_URL.FETCH_USER_INFO + encryptedEmail,
            onSuccess: userInfoVOReceived.type,
            method: "GET",
        }),
    );
};
export const fetchStates = (countryId: any) => (dispatch: any, getState: any) => {
    dispatch(stateVOReceived(DEFAULT_STATE));
    if (countryId !== SELECT_COUNTRY) {
        api({
            method: "GET",
            url: API_URL.FETCH_STATES + "/" + countryId,
            skipErrorHandling: true,
        }).then((res) => {
            if (
                res.data.success &&
                res.data.data &&
                res.data.data.result &&
                res.data.data.result.length
            ) {
                const resData = res.data.data.result;
                const list = [];
                list.push({ label: SELECT_STATE, value: "" });
                resData.map((item: any) => {
                    list.push({ label: item, value: item });
                });
                dispatch(stateVOReceived(list));
            }
        });
    }
};
export const getjobTitleArr = () => (dispatch: any) => {
    api({
        method: "GET",
        url: API_URL.FETCH_JOB_TITLES,
    }).then((res) => {
        if (
            res.data.success &&
            res.data.data &&
            res.data.data.jobtitles &&
            res.data.data.jobtitles.length
        ) {
            const resData = res.data.data.jobtitles;
            const list = [];
            list.push({ label: SELECT_JTITLE, value: "" });
            resData.map((item: any) => {
                list.push({ label: item, value: item });
            });
            dispatch(jobTitleArrReceived(list));
        }
    });
};
export const loadAllMaster = () => (dispatch: any) => {
    return dispatch(
        apiCallBegan({
            method: "GET",
            url: API_URL.ALL_MASTER,
            onSuccess: allMasterVOReceived.type,
        }),
    );
};
export const updateUserData = (formValues: any, broadcomEmail: string) => (dispatch: any) => {
    UpdateUserInfo(formValues, broadcomEmail)
        .then((data) => {
            if (data.success) {
                dispatch(updateSaveReceive("success"));
                dispatch(
                    showAlert({
                        type: "success",
                        message: data.data ? data.data : "User Profile details saved successfully.",
                    }),
                );
            } else {
                if(data.errors.errorMessage){
                    dispatch(
                        showAlert({
                            type: "danger",
                            message:data.errors.errorMessage ,
                        }),
                    );
                }
                dispatch(updateSaveReceive("error"));
            }
        })
        .catch((error) => {
            dispatch(updateSaveReceive("error"));
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors[0] &&
                error.response.data.errors[0].errorMessage
            ) {
                dispatch(
                    showAlert({
                        type: "danger",
                        message: error.response.data.errors[0].errorMessage
                            ? error.response.data.errors[0].errorMessage
                            : "Something went wrong",
                    }),
                );
            }
        });
};

//selectors
export const getCompanyNames = (state: any) => state.userManagement.companyNameList;
export const getUserDetails = (state: any) => state.userManagement.userDetails;
export const getUserInfoVo = (state: any) => state.userManagement.userInfoVO;
export const getCountryList = (state: any) => state.userManagement.countriesList;
export const getCountryValueList = (state: any) => state.userManagement.countriesValueList;
export const getStateVo = (state: any) => state.userManagement.stateVO;
export const getInitialValues = (state: any) => state.userManagement.initialValues;
export const getSearchInitialValues = (state: any) => state.userManagement.searchInitialValues;
export const getPageInfo = (state: any) => state.userManagement.pageInfo;
export const getSortInfo = (state: any) => state.userManagement.sortInfo;
export const getJobTitlesList = (state: any) => state.userManagement.jobTitleArr;
export const getSaveStatus = (state: any) => state.userManagement.saveStatus;
export const getIsEdit = (state: any) => state.userManagement.isEdit;
export const getEmailToEdit = (state: any) => state.userManagement.emailIdToEdit;
export const getUserInfo = (state: any) => state.userManagement.userInfo;
export const getUserInfoReceivedStatus = (state: any) => state.userManagement.userInfoReceived;
export const getSearchDetailsPayload = (state: any) => state.userManagement.searchDetailsPayload;

// reducer
export const userManagementReducer = slice.reducer;
