import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "../../../common-ui-modules/service/redux-helper/api-action-creator";
import { api } from "../../../common-ui-modules";

import { showAlert } from "../../../common-ui-modules/service/redux-helper/store/slice/globalAlertHandler.slice";
import { PROFILEBUILDER_API_URL } from "./constants";
import formatUserInfoVO from "./businessLogic/formatUserInfoVO";
import { UpdateUserInfo } from "./service/UpdateUserInfoService";
import { setVerifySupportValue } from "./service/SetVerifySupportValService";
import {
    PROFILE_BUILDER_OPTIONS,
    SOFTWARE_SECTION_IDX,
    BSN_SECTION_IDX,
    PARTNER_SECTION_IDX,
    ESTORE_SECTION_IDX,
    SEMICONDUCTOR_SECTION_IDX,
    TAP_PARTNER_SECTION_IDX,
    SERVICES_ARRAY,
    DEFAULT_STATE,
} from "../../../modules/Registration/ui/common/constants";
import { IS_PUBLIC } from "../common/constants";
import { updateServiceArray } from "./businessLogic/updateServiceArray";

const slice = createSlice({
    name: "profileBuilder",
    initialState: {
        userInfoVO: "",
        isSupportValValid: false,
        saveStatus: "",
        stateVO: DEFAULT_STATE,
        jobTitleArr: [{ label: "Select Job Title", value: "" }],
        portletPath: "profilebuilder",
        attemptCount: 3,
        attemptCount2: 3,
        attemptCount3: 3,
        supportValSuccess: "",
        serialNumberSuccess: "",
        customerIdSuccess: "",
        supportAddress: null,
        initialValues: {
            addressValues: {
                companyId: "",
                companyName: "",
                phone: "",
                address: "",
                address2: "",
                zipcode: "",
                city: "",
                state: "",
                countryName: "",
                country: "",
                bsnAutoApproved: false,
                timezone:"",
                website: null,
                tapCompanyName: "",
                newTapDomain: false,
            },
        },
        checkboxArray: [false, false, false, false, false, false],
        profileBuilderData: PROFILE_BUILDER_OPTIONS,
        servicesArray: JSON.parse(JSON.stringify(SERVICES_ARRAY)),
        profileType: "",
        timezoneList:[{ label: "Select Timezone", value: null }],
        timezoneValueList:[],
        domainErrorFlag:false
    },
    reducers: {
        userInfoVOReceived: (profileBuilder, action) => {
            const userInfoList = formatUserInfoVO(
                action.payload.data ? action.payload.data : {},
                profileBuilder.profileBuilderData,
            );
            profileBuilder.servicesArray = updateServiceArray(
                action.payload.data ? action.payload.data : {},
            );

            const initialValuesState = profileBuilder.initialValues;
            if (userInfoList && userInfoList.company) {
                initialValuesState.isReturningUser = true;
                initialValuesState.addressValues.address = userInfoList.addressLine1;
                initialValuesState.addressValues.address2 = userInfoList.addressLine2;
                initialValuesState.addressValues.phone = userInfoList.phone;
                initialValuesState.addressValues.city = userInfoList.city;
                initialValuesState.addressValues.state = userInfoList.state;
                initialValuesState.addressValues.zipcode = userInfoList.postalCode;
                initialValuesState.addressValues.companyId = userInfoList.companyId;
                initialValuesState.addressValues.companyName = userInfoList.companyName;
                initialValuesState.addressValues.country = userInfoList.country;
                initialValuesState.addressValues.countryName = userInfoList.countryName;
                initialValuesState.addressValues.timezone = userInfoList.timezone;
                initialValuesState.company = userInfoList.company;
                initialValuesState.addressValues.website = userInfoList.website;
                initialValuesState.addressValues.newTapDomain = userInfoList.newTapDomain;
            }
            profileBuilder.userInfoVO = userInfoList;
        },
        registrationInfoReceived: (profileBuilder, action) => {
            profileBuilder.userInfoVO = action.payload;
            profileBuilder.initialValues.company = profileBuilder.userInfoVO.company
                ? profileBuilder.userInfoVO.company
                : {};
            profileBuilder.initialValues.addressValues.companyId =
                profileBuilder.userInfoVO.companyId;
            profileBuilder.initialValues.addressValues.companyName =
                profileBuilder.userInfoVO.companyName;
        },
        verifySiteIdReceived: (profileBuilder, action) => {
            profileBuilder.isSupportValValid = action.payload.payload[0].success;
        },
        updateSaveReceive: (profileBuilder, action) => {
            profileBuilder.saveStatus = action.payload;
        },
        stateVOReceived: (profileBuilder, action) => {
            profileBuilder.stateVO = action.payload;
        },
        jobTitleArrReceived: (profileBuilder, action) => {
            profileBuilder.jobTitleArr = action.payload;
        },
        portletPathReceived: (profileBuilder, action) => {
            profileBuilder.portletPath = action.payload;
        },

        attemptCountDecrement: (profileBuilder, action) => {
            profileBuilder.attemptCount = profileBuilder.attemptCount - 1;
        },
        attemptCountDecrement2: (profileBuilder, action) => {
            action.payload
                ? (profileBuilder.attemptCount2 = action.payload)
                : (profileBuilder.attemptCount2 = profileBuilder.attemptCount2 - 1);
        },
        attemptCountDecrement3: (profileBuilder, action) => {
            profileBuilder.attemptCount3 = profileBuilder.attemptCount3 - 1;
        },

        attemptCountReset: (profileBuilder, action) => {
            profileBuilder.attemptCount = 3;
        },
        attemptCountReset2: (profileBuilder, action) => {
            profileBuilder.attemptCount2 = 3;
        },
        attemptCountReset3: (profileBuilder, action) => {
            profileBuilder.attemptCount3 = 3;
        },

        setSupportValSuccess: (profileBuilder, action) => {
            profileBuilder.supportValSuccess = action.payload;
            profileBuilder.supportAddress = action.payload;
        },
        setSerialNumberSuccess: (profileBuilder, action) => {
            profileBuilder.serialNumberSuccess = action.payload;
        },
        setCustomerIdSuccess: (profileBuilder, action) => {
            profileBuilder.customerIdSuccess = action.payload;
        },

        resetSupportValSuccess: (profileBuilder, action) => {
            profileBuilder.supportValSuccess = "";
        },
        resetSerialNumberSuccess: (profileBuilder, action) => {
            profileBuilder.serialNumberSuccess = "";
        },
        resetCustomerIdSuccess: (profileBuilder, action) => {
            profileBuilder.customerIdSuccess = "";
        },
        addressValuesReceived: (profileBuilder, action) => {
            if (action.payload) {
                const key = Object.keys(action.payload)[0];
                profileBuilder.initialValues.addressValues[key] = action.payload[key];
            }
        },
        setCheckedValues: (profileBuilder, action) => {
            profileBuilder.checkboxArray[action.payload] =
                !profileBuilder.checkboxArray[action.payload];
            profileBuilder.profileBuilderData[action.payload].isRequested =
                profileBuilder.checkboxArray[action.payload];
        },
        setProfileBuilderData: (profileBuilder, action) => {
            const payload = action.payload;
            profileBuilder.profileBuilderData[payload.idx].data = payload.data;
        },
        updateProfileBuilder: (profileBuilder, action) => {
            const payload = action.payload;
            const {
                bsnApprovalStatus,
                estoreStatus,
                partnerStatus,
                semiconductorStatus,
                supportStatus,
                tapStatus
            } = payload;
            const sectionRequestedArray = [
                "APPROVED",
                "SUCCESS",
                "PENDING",
                "approved",
                "success",
                "pending",
            ];

            profileBuilder.profileBuilderData[SOFTWARE_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(supportStatus);
            profileBuilder.profileBuilderData[BSN_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(bsnApprovalStatus);
            profileBuilder.profileBuilderData[PARTNER_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(partnerStatus);
            profileBuilder.profileBuilderData[ESTORE_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(estoreStatus);
            profileBuilder.profileBuilderData[SEMICONDUCTOR_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(semiconductorStatus);
            profileBuilder.profileBuilderData[TAP_PARTNER_SECTION_IDX].defaultChecked =
                sectionRequestedArray.includes(tapStatus);
        },
        updateServiceArrayList: (profileBuilder, action) => {
            profileBuilder.servicesArray = action.payload;
        },
        updateProfileType: (profileBuilder, action) => {
            profileBuilder.profileType = action.payload;
        },
        setTimezoneList:(profileBuilder, action) => {
            if (action.payload) {
                const List = action.payload.data.result;
                let timezones = [];
                timezones = List.map((item: any) => {
                    return { label: `${item.timezone} - ${item.timezoneName}`, value: item.timezone };
                });
                timezones.unshift({ label: "Select Timezone", value: "" });
                profileBuilder.timezoneList=timezones
                const valueList: any = [];
                timezones.map((item: any) => {
                    !!item.value && valueList.push(item.value.toString());
                });
                profileBuilder.timezoneValueList=valueList
            }
        },
        updateErrorDomainFlag: (profileBuilder, action) => {
            profileBuilder.domainErrorFlag = action.payload;
        },
    },
});

export const {
    userInfoVOReceived,
    registrationInfoReceived,
    verifySiteIdReceived,

    stateVOReceived,
    jobTitleArrReceived,
    portletPathReceived,
    updateSaveReceive,
    attemptCountReset,
    attemptCountReset2,
    attemptCountReset3,

    attemptCountDecrement,
    attemptCountDecrement2,
    attemptCountDecrement3,

    setSupportValSuccess,
    setSerialNumberSuccess,
    setCustomerIdSuccess,

    resetSupportValSuccess,
    resetSerialNumberSuccess,
    resetCustomerIdSuccess,

    addressValuesReceived,
    setCheckedValues,
    setProfileBuilderData,
    updateProfileBuilder,
    updateServiceArrayList,
    updateProfileType,
    setTimezoneList,
    updateErrorDomainFlag
} = slice.actions;

//custom actions
export const getUserInfo = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: IS_PUBLIC
                ? PROFILEBUILDER_API_URL.FETCH_USER_INFO_WEB
                : PROFILEBUILDER_API_URL.FETCH_USER_INFO,
            onSuccess: userInfoVOReceived.type,
            method: "GET",
        }),
    );
};
export const setVerifySupportVal = (data, flag) => (dispatch, getState) => {
    const userInfoVO = getState().profileBuilder.userInfoVO;
    if (data.supportTypeId !== "5") {
        data.emailId = userInfoVO.email ? userInfoVO.email : "";
    }
    setVerifySupportValue(data, getProfilePath(getState())).then((response) => {
        if (!response.data || (response.data.data && response.data.data.success === false)) {
            if (flag === "supportSiteId") {
                dispatch(attemptCountDecrement());
                dispatch(setSupportValSuccess(null));
            } else if (flag === "serialNumber") {
                dispatch(attemptCountDecrement2());
                dispatch(setSerialNumberSuccess(response.data.data));
                if (
                    response.data.errors[0].errorMessage &&
                    response.data.errors[0].errorMessage
                        .toUpperCase()
                        .includes("ALL ATTEMPTS EXHAUSTED")
                ) {
                    dispatch(attemptCountDecrement2(-1));
                }
            } else if (flag === "customerId") {
                dispatch(attemptCountDecrement3());
                dispatch(setCustomerIdSuccess(null));
            }
        } else {
            if (flag === "supportSiteId") {
                dispatch(attemptCountReset());
                dispatch(setSupportValSuccess(response.data.data));
            } else if (flag === "serialNumber") {
                dispatch(setSerialNumberSuccess(response.data.data));
            } else if (flag === "customerId") {
                dispatch(attemptCountReset3());
                dispatch(setCustomerIdSuccess(response.data.data));
            }
        }
    });
};

export const updateUserData = () => (dispatch, getState) => {
    const currentData = getState().profileBuilder.profileBuilderData;
    const existingData = getState().profileBuilder.userInfoVO;
    const supportAddress = getState().profileBuilder.supportAddress;
    const profileBuilderData = getState().profileBuilder.profileBuilderData;

    UpdateUserInfo(
        currentData,
        existingData,
        supportAddress,
        profileBuilderData,
        getProfilePath(getState()),
    )
        .then((res) => {
            const data=res.data
            if (
                (data && data.success) ||
                (data.data.errors &&
                    data.data.errors[0].message &&
                    data.data.errors[0].message.toUpperCase() === "REGISTRATION_UNDER_REVIEW") ||
                (data.data.errors &&
                    data.data.errors[0].message &&
                    data.data.errors[0].message.toUpperCase() === "UPGRADE_PENDING")
            ) {
                dispatch(updateSaveReceive(data.data));
            } else {
                if (
                    !data &&
                    currentData[0] &&
                    currentData[0].isRequested &&
                    currentData[0].supportValue === 1 &&
                    currentData[0].data &&
                    currentData[0].data.siteId
                ) {
                    //error handling for foreign characters
                    dispatch(
                        showAlert({
                            message:
                                'Unable to process Site ID, please contact <a href="/web/ecx/contact-support">Customer Care</a>',
                            timeout: "",
                        }),
                    );
                }
                dispatch(updateSaveReceive("error"));
            }
        })
        .catch((error) => {
            if(error.response.data.errors[0].errorMessage){
                dispatch(
                    showAlert({
                        type: "danger",
                        message:error.response.data.errors[0].errorMessage ,
                    }),
                );
            }
            dispatch(updateSaveReceive("error"));
        });
};

export const fetchStates = (countryId) => (dispatch, getState) => {
    dispatch(stateVOReceived(DEFAULT_STATE));
    if (countryId !== "Select Country") {
        api({
            method: "GET",
            url:
                (IS_PUBLIC
                    ? PROFILEBUILDER_API_URL.FETCH_STATES_WEB
                    : PROFILEBUILDER_API_URL.FETCH_STATES) +
                "/" +
                countryId,
            skipErrorHandling: true,
        }).then((res) => {
            if (
                res.data.success &&
                res.data.data &&
                res.data.data.result &&
                res.data.data.result.length
            ) {
                const resData = res.data.data.result;
                const list = [];
                list.push({ label: "Select State", value: "" });
                resData.map((item) => {
                    list.push({ label: item, value: item });
                });
                dispatch(stateVOReceived(list));
            }
        });
    }
};

export const fetchTimezone = () => (dispatch) => {
    const timezoneUrl=IS_PUBLIC
        ? PROFILEBUILDER_API_URL.FETCH_TIMEZONE_WEB
        : PROFILEBUILDER_API_URL.FETCH_TIMEZONE
    return dispatch(
        apiCallBegan({
            method: "GET",
            url: timezoneUrl ,
            onSuccess: setTimezoneList.type,
            skipErrorHandling: true,
        }),
    );
};

export const checkUserDomain = (email) => (dispatch,getState) => {

    api({
        method: "POST",
        url: PROFILEBUILDER_API_URL.CHECK_USER_DOMAIN,
        data:{
             "loggedInUserEmail": email
        },
        skipErrorHandling: true,
    }).then((res) => {
        if(res.data.data.nonCorporateDomain){
            dispatch(
                showAlert({
                    position:"top-right",
                    timeout:"600000",
                    type: "danger",
                    message:"This option is not available to users who have registered with a non-corporate email domain." ,
                }),
            );
            dispatch(updateErrorDomainFlag(false))
        }else{
            dispatch(updateErrorDomainFlag(true))
        }
        
    })

}

export const getjobTitleArr = () => (dispatch, getState) => {
    api({
        method: "GET",
        url: IS_PUBLIC
            ? PROFILEBUILDER_API_URL.FETCH_JOB_TITLES_WEB
            : PROFILEBUILDER_API_URL.FETCH_JOB_TITLES,
        skipErrorHandling: true,
    }).then((res) => {
        if (
            res.data.success &&
            res.data.data &&
            res.data.data.jobtitles &&
            res.data.data.jobtitles.length
        ) {
            const resData = res.data.data.jobtitles;
            const list = [];
            list.push({ label: "Select Job Title", value: "" });
            resData.map((item) => {
                list.push({ label: item, value: item });
            });
            dispatch(jobTitleArrReceived(list));
        }
    });
};

//selectors
export const userInfoObject = (state) => {
    return state.profileBuilder.userInfoVO;
};
export const getIsValidSupportVal = (state) => {
    return state.profileBuilder.isSupportValValid;
};
export const getSaveStatus = (state) => {
    return state.profileBuilder.saveStatus;
};

const rawStatesList = (state) => state.profileBuilder.stateVO;

export const getProfilePath = (state) => {
    return state.profileBuilder.portletPath;
};

//counters for forms
export const getAttemptCountForSupportSiteId = (state) => {
    return state.profileBuilder.attemptCount;
};
export const getAttemptCountForSerialNumber = (state) => {
    return state.profileBuilder.attemptCount2;
};
export const getAttemptCountForCustomerId = (state) => {
    return state.profileBuilder.attemptCount3;
};

//reseting success messages for form inputs
export const getSupportValSuccess = (state) => {
    return state.profileBuilder.supportValSuccess;
};
export const getSerialNumberSuccess = (state) => {
    return state.profileBuilder.serialNumberSuccess;
};
export const getCustomerIdSuccess = (state) => {
    return state.profileBuilder.customerIdSuccess;
};
export const getSupportAddress = (state) => {
    return state.profileBuilder.supportAddress;
};
export const getInitialValues = (state) => {
    return state.profileBuilder.initialValues;
};
export const getCheckedArray = (state) => {
    return state.profileBuilder.checkboxArray;
};
export const getProfileBuilderData = (state) => {
    return state.profileBuilder.profileBuilderData;
};

export const getUserInfoVo = (state) => {
    return state.profileBuilder.userInfoVO;
};
export const getStateVo = (state) => {
    return state.profileBuilder.stateVO;
};

export const getJobTitlesList = (state) => {
    return state.profileBuilder.jobTitleArr;
};

export const getServiceArrayData = (state) => {
    return state.profileBuilder.servicesArray;
};

export const getProfileType = (state) => {
    return state.profileBuilder.profileType;
};

export const getTimezoneList=(state:any)=>state.profileBuilder.timezoneList;
export const getTimezoneValueList=(state:any)=>state.profileBuilder.timezoneValueList;
export const getDomainErrorFlag=(state:any)=>state.profileBuilder.domainErrorFlag;

// reducer
export const profileBuilderReducer = slice.reducer;
