import { BASEPATH } from "../../../utils/constants/constants";

const PROFILEBUILDER_API_URL = {
    FETCH_USER_INFO_WEB: `${BASEPATH}/api/web/registration/userProfile/fetch`,
    VALIDATE_SUPPORT_VAL_WEB: `${BASEPATH}/api/web/registration/userProfile/validateSupportTypeVal`,
    FETCH_STATES_WEB: `${BASEPATH}/api/web/registration/masterService/getStates`,
    FETCH_TIMEZONE_WEB: `${BASEPATH}/api/web/registration/masterService/timezoneList`,
    FETCH_JOB_TITLES_WEB: `${BASEPATH}/api/web/registration/masterService/getJobTitles`,
    UPGRADE_USER_INFO_WEB: `${BASEPATH}/api/web/registration/userProfile/upgrade`,
    FETCH_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/fetch`,
    VALIDATE_SUPPORT_VAL: `${BASEPATH}/api/ms/registration/userProfile/validateSupportTypeVal`,
    FETCH_STATES: `${BASEPATH}/api/ms/registration/masterService/getStates`,
    FETCH_JOB_TITLES: `${BASEPATH}/api/ms/registration/masterService/getJobTitles`,
    UPGRADE_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/upgrade`,
    FETCH_TIMEZONE: `${BASEPATH}/api/ms/registration/masterService/timezoneList`,
    CHECK_USER_DOMAIN: `${BASEPATH}/api/web/registration/checkNonCorporateDomain`,
};

export { PROFILEBUILDER_API_URL };
