import { BASEPATH } from "../../utils/constants/constants";

const API_URL = {
    FETCH_COMPANY: `${BASEPATH}/api/ms/registration/masterService/getCompanyNames`,
    USER_SEARCH: `${BASEPATH}/api/web/registration/userProfile/searchUser`,
    FETCH_USER_INFO: `${BASEPATH}/api/ms/registration/userDetails/fetch/`,
    ALL_MASTER: `${BASEPATH}/api/ms/registration/masterService/getCountries`,
    FETCH_STATES: `${BASEPATH}/api/ms/registration/masterService/getStates`,
    FETCH_JOB_TITLES: `${BASEPATH}/api/web/registration/masterService/getJobTitles`,
    UPDATE_USER_INFO: `${BASEPATH}/api/ms/registration/userProfile/update`,
};

export { API_URL };
