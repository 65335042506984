import React from "react";
import RowDetail from "../common/RowDetail";

const MasterDetails = (props: any) => {
    let masterDetails = props.userDetails.data.masterDetails;
    const userType = (props.userDetails.data.oktaProfile.email.indexOf("@broadcom") !== -1) ? "Internal" : "";

    if (userType === "Internal") {
        masterDetails = {
            ...masterDetails, ...{
                supportStatus : "-",
                partnerStatus : "-",
                semiconductorStatus : "-",
                isupplierStatus : "-",
                estoreStatus: "-",
                bsnApprovalStatus : "-",
                gtmScreened : "-",
                complianceStatus : "-",
                supportType : "N/A",
                siteId : "N/A",
            }
        };
    }
    else {
        masterDetails = {
            ...masterDetails, ...{
                supportStatus : masterDetails.supportStatus ? masterDetails.supportStatus : "-",
                partnerStatus : masterDetails.partnerStatus ? masterDetails.partnerStatus : "-",
                semiconductorStatus : masterDetails.semiconductorStatus ? masterDetails.semiconductorStatus : "-",
                isupplierStatus : masterDetails.isupplierStatus ? masterDetails.isupplierStatus : "-",
                estoreStatus: masterDetails.estoreStatus ? masterDetails.estoreStatus : "-",
                bsnApprovalStatus: masterDetails.bsnApprovalStatus ? masterDetails.bsnApprovalStatus : "-",
                complianceStatus: masterDetails.complianceStatus ? masterDetails.complianceStatus : "-",
                supportType: masterDetails.supportType ? masterDetails.supportType : "N/A",
                siteId: masterDetails.siteId ? masterDetails.siteId : "N/A",
            }
        };
    }

    return (
        <React.Fragment>
            <div id="masterDetails" className="pt-4">
                <h2 className="text-red">Master Details</h2>

                <RowDetail label="Screening Result" value="" />
                <RowDetail label="Broadcom Software" value={masterDetails.supportStatus} />
                <RowDetail label="Broadcom Partner" value={masterDetails.partnerStatus} />
                <RowDetail label="Semiconductors" value={masterDetails.semiconductorStatus} />
                <RowDetail label="iSupplier" value={masterDetails.isupplierStatus} />
                <RowDetail label="eStore" value={masterDetails.estoreStatus} />
                <RowDetail label="Brocade Storage Networking" value={masterDetails.bsnApprovalStatus} />
                <RowDetail label="GTM Screened" value={masterDetails.gtmScreened} />
                <RowDetail label="Compliance Status" value={masterDetails.complianceStatus} />
                <RowDetail label="Military User" value={masterDetails.militaryUser} />
                <RowDetail label="Site ID" value={masterDetails.siteId} />
                <RowDetail label="Created On" value={masterDetails.createdDate} />

                <hr />

            </div>
        </React.Fragment>
    );
};

export default MasterDetails;